import React from "react";
import "./Popup.css";
import image from "./popup.png";
const Popup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleLinkClick = () => {
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
        <img src={image} alt="Popup" className="popup-image" />
        <a
          className="popup-gesture regular"
          href="https://docs.google.com/forms/d/e/1FAIpQLScN74koiHX2Mge9jiyG3BXW855yXfAZBTd_f5w9WEiw2L6JMw/viewform"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
        >
          Registration Form
        </a>
      </div>
    </div>
  );
};

export default Popup;
