import { useState } from "react";
import { Link } from "react-router-dom";
import coursecontent from "./Coursecontent";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const coursesToShow = coursecontent.filter(
    (course) => course.nav === "Navbar"
  );

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap px-6 py-4 md:px16">
        <div className="flex items-center flex-shrink-0 text-white">
          <Link to="/">
            <LazyLoadImage
              src="https://newus.in/images/logo/logo.svg"
              className="w-100 h-[80px] mr-4"
              alt="Logo"
              effect="blur"
            />
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <svg
              className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg
              className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div
          className={` block lg:flex lg:items-center ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <div className="text-sm lg:flex-grow">
            <ul className="text-sm lg:flex-grow">
              <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4">
                <Link
                  to="/"
                  className="flex md:inline-flex p-4 items-center hover:bg-gray-50 bold text-[15px]"
                >
                  <span>Home</span>
                </Link>
              </li>
              {/* <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4">
                <Link
                  to="/"
                  className="flex md:inline-flex p-4 items-center hover:bg-gray-50 bold text-[15px]"
                >
                  <span>Company</span>
                </Link>
              </li> */}
              <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4">
                <Link
                  to="/About"
                  className="flex md:inline-flex p-4 items-center hover:bg-gray-50 bold text-[15px]"
                >
                  <span>About us</span>
                </Link>
              </li>
              <li className="relative parent mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4">
                <Link
                  to="/CourseList"
                  className="flex justify-center md:inline-flex p-4 items-center hover:bg-gray-50 space-x-2 bold text-[15px]"
                >
                  <span>Programs</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 fill-current pt-1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                  </svg>
                </Link>
                <ul className="child transition duration-300 md:absolute top-full left-[0%] md:w-96 bg-white md:shadow-lg md:rounded-b ">
                  {coursesToShow.map((til, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/Course/${til.name}`}
                          className="flex px-4 py-3 hover:bg-gray-50 regular"
                        >
                          {til.title}
                        </Link>
                      </li>
                    );
                  })}
                  <li>
                    <Link
                      to="/CourseList"
                      className="flex px-4 py-3 hover:bg-gray-50 regular"
                    >
                      View All course
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4">
                <Link
                  to="/Gallery"
                  className="flex md:inline-flex p-4 items-center hover:bg-gray-50 bold text-[15px]"
                >
                  <span>Gallery</span>
                </Link>
              </li> */}
              <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4">
                <Link
                  to="/Contact"
                  className="flex md:inline-flex p-4 items-center hover:bg-gray-50 bold text-[15px]"
                >
                  <span>Contact</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex gap-1">
            <Link to="https://wa.me/8679686796" target="_">
              <button className="button">
                <span className="button-decor"></span>
                <div className="button-content">
                  <div className="button__icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" fill="none" />
                      <g clipPath="url(#clip0_0_1)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18 24H6C4.4087 24 2.88258 23.3679 1.75736 22.2426C0.632141 21.1174 0 19.5913 0 18V6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0L18 0C19.5913 0 21.1174 0.632141 22.2426 1.75736C23.3679 2.88258 24 4.4087 24 6V18C24 19.5913 23.3679 21.1174 22.2426 22.2426C21.1174 23.3679 19.5913 24 18 24Z"
                          fill="#00D264"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.25 6.75H18C18.8659 6.75109 19.6992 6.41929 20.3273 5.82325C20.9555 5.2272 21.3305 4.41252 21.3748 3.54772C21.4191 2.68292 21.1293 1.83417 20.5654 1.17704C20.0015 0.519908 19.2065 0.104682 18.345 0.01725C18.2303 0.010875 18.1166 0 18 0H6C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6L0 18C0 15.0163 1.18526 12.1548 3.29505 10.045C5.40483 7.93526 8.26632 6.75 11.25 6.75Z"
                          fill="#00EB78"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18 12C16.8133 12 15.6533 12.3519 14.6666 13.0112C13.6799 13.6705 12.9109 14.6075 12.4567 15.7039C12.0026 16.8003 11.8838 18.0067 12.1153 19.1705C12.3468 20.3344 12.9182 21.4035 13.7574 22.2426C14.5965 23.0818 15.6656 23.6532 16.8295 23.8847C17.9933 24.1162 19.1997 23.9974 20.2961 23.5433C21.3925 23.0892 22.3295 22.3201 22.9888 21.3334C23.6481 20.3467 24 19.1867 24 18V6C24 7.5913 23.3679 9.11742 22.2426 10.2426C21.1174 11.3679 19.5913 12 18 12Z"
                          fill="#00B950"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.12739 17.6599C8.2272 17.6315 8.33182 17.6241 8.43464 17.6382C8.53745 17.6522 8.63624 17.6875 8.72476 17.7416C10.2158 18.6299 11.98 18.9429 13.6856 18.6216C15.3912 18.3003 16.9206 17.3669 17.9862 15.9969C19.0518 14.627 19.5802 12.9149 19.4719 11.1827C19.3636 9.45052 18.6262 7.81755 17.3983 6.59096C16.1704 5.36437 14.5367 4.62868 12.8043 4.52226C11.072 4.41583 9.36051 4.94601 7.9917 6.01308C6.6229 7.08014 5.69113 8.61056 5.37165 10.3165C5.05216 12.0224 5.36698 13.7863 6.25689 15.2764C6.31084 15.3646 6.3459 15.4631 6.35985 15.5656C6.37379 15.6681 6.36631 15.7724 6.33789 15.8719C6.12901 16.611 5.62501 18.375 5.62501 18.375L8.12739 17.6599ZM4.95714 16.0252C3.88495 14.2179 3.50943 12.0813 3.9011 10.0166C4.29277 7.95198 5.42469 6.10134 7.0843 4.81221C8.7439 3.52308 10.817 2.88414 12.9144 3.01538C15.0118 3.14662 16.9891 4.03901 18.475 5.52497C19.961 7.01093 20.8534 8.98824 20.9846 11.0856C21.1159 13.183 20.4769 15.2561 19.1878 16.9157C17.8987 18.5753 16.048 19.7072 13.9834 20.0989C11.9187 20.4906 9.78213 20.1151 7.97476 19.0429C7.97476 19.0429 5.58339 19.7261 4.39914 20.0647C4.3348 20.0831 4.26673 20.0839 4.20198 20.0671C4.13723 20.0503 4.07814 20.0165 4.03084 19.9692C3.98353 19.9219 3.94973 19.8628 3.93293 19.798C3.91612 19.7333 3.91693 19.6652 3.93526 19.6009L4.95714 16.0252Z"
                          fill="white"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.24286 9.29476C8.35949 10.9739 9.07612 12.5552 10.2619 13.7498C11.4477 14.9444 13.0236 15.6727 14.7019 15.8018H14.7026C14.9706 15.8225 15.2398 15.785 15.4919 15.6918C15.744 15.5985 15.9729 15.4518 16.1629 15.2618L16.4246 15C16.5482 14.8763 16.6176 14.7087 16.6177 14.5339V13.9781C16.6177 13.9085 16.5983 13.8402 16.5617 13.7809C16.5251 13.7216 16.4727 13.6737 16.4104 13.6425L14.7382 12.8066C14.6678 12.7714 14.5881 12.7592 14.5103 12.7717C14.4326 12.7843 14.3608 12.8211 14.3051 12.8768L13.5776 13.6043C13.534 13.6479 13.4802 13.6801 13.4212 13.698C13.3621 13.716 13.2995 13.719 13.239 13.707L13.2356 13.7063C12.5096 13.5611 11.8428 13.2042 11.3193 12.6807C10.7958 12.1572 10.4389 11.4904 10.2937 10.7644L10.293 10.761C10.2809 10.7005 10.284 10.6379 10.302 10.5788C10.3199 10.5198 10.3521 10.466 10.3957 10.4224L11.1232 9.69489C11.1789 9.6392 11.2157 9.56739 11.2283 9.48965C11.2408 9.41191 11.2286 9.33218 11.1934 9.26176L10.3575 7.58964C10.3263 7.52733 10.2784 7.47493 10.2191 7.4383C10.1598 7.40168 10.0915 7.38227 10.0219 7.38226H9.54936C9.43716 7.38229 9.32621 7.40587 9.2237 7.45148C9.12119 7.49708 9.02938 7.5637 8.95423 7.64701L8.70523 7.92376C8.5415 8.10547 8.41601 8.31827 8.33625 8.54949C8.25649 8.78072 8.22409 9.02563 8.24098 9.26964L8.24286 9.29476Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_0_1">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span className="button__text reglur">WhatsApp</span>
                </div>
              </button>
            </Link>
            <Link to="tel:8679686796" target="_">
              <button className="call-button liner">
                <div className="svg-wrapper-1">
                  <div className="svg-wrapper">
                    <svg
                      fill="#000000"
                      height="24"
                      width="24"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 473.806 473.806"
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
                            c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
                            c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
                            c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
                            c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
                            c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
                            c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
                            C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
                            c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
                            c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
                            c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
                            c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
                            c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
                            c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
                            l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
                            C420.456,377.706,420.456,388.206,410.256,398.806z"
                          />
                          <path
                            d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
          c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
          S248.656,111.506,256.056,112.706z"
                          />
                          <path
                            d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
          c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
          c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <span className="reglur">Call Now</span>
              </button>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
