import React, { createContext, memo } from "react";
import { useParams } from "react-router-dom";
import coursecontent from "./Coursecontent";
import Showform from "../Forms/Showform";
export const brochure = createContext();
const svgtime = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.1939 16.6535L27.1622 16.6218C26.5768 16.0364 25.6282 16.0361 25.043 16.6216C23.7982 17.8668 21.7939 19.8711 20.9403 20.7248C20.8113 20.8538 20.658 20.9561 20.4894 21.0258C20.3207 21.0955 20.14 21.1312 19.9575 21.1309H17.0472C18.0083 21.1259 18.7861 20.3457 18.7861 19.3834C18.7861 18.418 18.0035 17.6355 17.0381 17.6355H13.2581C12.7924 17.6355 12.3314 17.7289 11.9025 17.9103C11.4736 18.0916 11.0854 18.3572 10.761 18.6913L8.39166 21.1311H7.49951V26.9515H17.2213C18.3292 26.9515 19.3916 26.5115 20.1751 25.7282L27.1939 18.7107C27.7618 18.1424 27.7619 17.2216 27.1939 16.6535Z"
      fill="#FCC4AE"
    />
    <path
      d="M27.2764 16.7448C27.3009 17.1461 27.1612 17.5556 26.8545 17.8624L19.8358 24.8799C19.0524 25.6632 17.9899 26.1032 16.882 26.1032H7.49939V26.9512H17.2211C18.329 26.9512 19.3915 26.5112 20.175 25.7279L27.1937 18.7103C27.7325 18.1717 27.7595 17.3163 27.2764 16.7448ZM17.038 21.1313H14.176C14.1086 21.1313 14.0438 21.1045 13.9961 21.0568C13.9484 21.0091 13.9216 20.9444 13.9216 20.8769C13.9216 20.8094 13.9484 20.7447 13.9961 20.697C14.0438 20.6493 14.1086 20.6225 14.176 20.6225H16.6988C17.6642 20.6225 18.4468 19.8399 18.4468 18.8745C18.4468 18.6265 18.3944 18.3911 18.3014 18.1774C18.6126 18.5017 18.7862 18.9339 18.786 19.3833C18.786 20.3487 18.0034 21.1313 17.038 21.1313Z"
      fill="#F2AD97"
    />
    <path
      d="M16.4476 20.4864H16.8021C17.0508 20.4864 17.2892 20.3877 17.465 20.2119C17.6409 20.036 17.7396 19.7976 17.7396 19.5489V19.2179C17.7396 18.9693 17.6409 18.7308 17.465 18.555C17.2892 18.3792 17.0508 18.2804 16.8021 18.2804H16.4476C16.3855 18.2804 16.3259 18.3051 16.2819 18.349C16.2379 18.393 16.2133 18.4526 16.2133 18.5148V20.2521C16.2133 20.3142 16.2379 20.3738 16.2819 20.4178C16.3259 20.4618 16.3855 20.4864 16.4476 20.4864Z"
      fill="#FFD2C0"
    />
    <path
      d="M6.91341 28.6918H2.96619C2.81079 28.6918 2.66175 28.6301 2.55187 28.5202C2.44198 28.4103 2.38025 28.2613 2.38025 28.1059V19.9763C2.38025 19.8209 2.44198 19.6719 2.55187 19.562C2.66175 19.4521 2.81079 19.3904 2.96619 19.3904H6.91341C7.06881 19.3904 7.21785 19.4521 7.32773 19.562C7.43762 19.6719 7.49935 19.8209 7.49935 19.9763V28.1059C7.49935 28.2613 7.43762 28.4103 7.32773 28.5202C7.21785 28.6301 7.06881 28.6918 6.91341 28.6918Z"
      fill="#478CF7"
    />
    <path
      d="M3.0835 25.8793C3.02134 25.8793 2.96172 25.8546 2.91777 25.8106C2.87381 25.7667 2.84912 25.7071 2.84912 25.6449V21.0746C2.84912 21.0124 2.87381 20.9528 2.91777 20.9089C2.96172 20.8649 3.02134 20.8402 3.0835 20.8402C3.14566 20.8402 3.20527 20.8649 3.24922 20.9089C3.29318 20.9528 3.31787 21.0124 3.31787 21.0746V25.6449C3.31787 25.7071 3.29318 25.7667 3.24922 25.8106C3.20527 25.8546 3.14566 25.8793 3.0835 25.8793ZM3.0835 20.3715C3.02134 20.3715 2.96172 20.3468 2.91777 20.3028C2.87381 20.2589 2.84912 20.1993 2.84912 20.1371V20.0935C2.84912 20.0313 2.87381 19.9717 2.91777 19.9278C2.96172 19.8838 3.02134 19.8591 3.0835 19.8591C3.14566 19.8591 3.20527 19.8838 3.24922 19.9278C3.29318 19.9717 3.31787 20.0313 3.31787 20.0935V20.1371C3.31787 20.1993 3.29318 20.2589 3.24922 20.3028C3.20527 20.3468 3.14566 20.3715 3.0835 20.3715Z"
      fill="#5E9CF7"
    />
    <path
      d="M7.49935 19.9763C7.49935 19.8209 7.43762 19.6719 7.32773 19.562C7.21785 19.4521 7.06881 19.3904 6.91341 19.3904H5.1556V25.7621C5.1556 25.9175 5.09387 26.0665 4.98398 26.1764C4.8741 26.2863 4.72506 26.348 4.56966 26.348H2.38025V28.1059C2.38025 28.2613 2.44198 28.4103 2.55187 28.5202C2.66175 28.6301 2.81079 28.6918 2.96619 28.6918H6.91341C7.06881 28.6918 7.21785 28.6301 7.32773 28.5202C7.43762 28.4103 7.49935 28.2613 7.49935 28.1059V19.9763Z"
      fill="#377DE2"
    />
    <path
      d="M7.49935 19.9763C7.49935 19.8209 7.43762 19.6719 7.32773 19.562C7.21785 19.4521 7.06881 19.3904 6.91341 19.3904H6.32748V26.934C6.32748 27.0894 6.26574 27.2384 6.15586 27.3483C6.04597 27.4582 5.89694 27.5199 5.74154 27.5199H2.38025V28.1059C2.38025 28.2613 2.44198 28.4103 2.55187 28.5202C2.66175 28.6301 2.81079 28.6918 2.96619 28.6918H6.91341C7.06881 28.6918 7.21785 28.6301 7.32773 28.5202C7.43762 28.4103 7.49935 28.2613 7.49935 28.1059V19.9763Z"
      fill="#2266D3"
    />
    <path
      d="M19.6106 11.0585C19.4822 11.1655 19.3797 11.3003 19.3111 11.4528C19.2425 11.6052 19.2095 11.7713 19.2145 11.9384C19.2397 12.7709 18.3692 13.3304 17.6225 12.9616C17.3741 12.8389 17.0886 12.8141 16.8228 12.8922C16.5569 12.9703 16.3302 13.1455 16.1876 13.383C16.1846 13.3878 16.1811 13.3918 16.1782 13.3966L18.3862 16.9693C18.519 17.1841 18.8324 17.1813 18.9612 16.9641L19.8483 15.4693L21.6648 15.3082C21.9139 15.286 22.0525 15.0093 21.921 14.7966L19.6106 11.0585ZM14.2951 13.3829C14.1524 13.1454 13.9257 12.9702 13.6599 12.8922C13.394 12.8141 13.1086 12.8388 12.8601 12.9615C12.1134 13.3303 11.2428 12.7708 11.2682 11.9383C11.2732 11.7712 11.2402 11.6051 11.1716 11.4527C11.1029 11.3002 11.0005 11.1654 10.8721 11.0583L8.56183 14.7963C8.43034 15.009 8.56898 15.2857 8.818 15.3078L10.6345 15.4689L11.5216 16.9638C11.6505 17.1809 11.9638 17.1837 12.0966 16.9689L14.3046 13.3962C14.3016 13.3917 14.298 13.3877 14.2951 13.3829Z"
      fill="#E63950"
    />
    <path
      d="M15.989 1.60075C16.6016 1.0365 17.5945 1.32806 17.8048 2.13396C17.8748 2.40202 18.043 2.63398 18.2761 2.78377C18.5092 2.93356 18.79 2.99024 19.063 2.94255C19.8834 2.79911 20.561 3.58111 20.3022 4.37271C20.2162 4.63607 20.2323 4.92215 20.3474 5.17418C20.4625 5.4262 20.6682 5.62572 20.9236 5.73314C21.6912 6.05599 21.8386 7.08032 21.1928 7.60626C20.978 7.78125 20.8369 8.03064 20.7975 8.30488C20.7581 8.57912 20.8232 8.85816 20.98 9.08657C21.4514 9.77318 21.0214 10.7145 20.1939 10.8081C19.9186 10.8391 19.6651 10.9726 19.4836 11.182C19.3022 11.3914 19.2062 11.6614 19.2146 11.9383C19.2399 12.7708 18.3693 13.3303 17.6226 12.9615C17.3742 12.8388 17.0887 12.814 16.8229 12.8921C16.557 12.9702 16.3303 13.1454 16.1877 13.3829C15.7589 14.0968 14.724 14.0968 14.2952 13.3829C14.1525 13.1454 13.9258 12.9702 13.6599 12.8922C13.3941 12.8141 13.1087 12.8389 12.8602 12.9615C12.1135 13.3303 11.2429 12.7708 11.2682 11.9383C11.2767 11.6614 11.1806 11.3914 10.9992 11.182C10.8178 10.9726 10.5642 10.8391 10.2889 10.8081C9.46133 10.7145 9.03148 9.77329 9.50281 9.08657C9.65957 8.85814 9.7247 8.57912 9.68527 8.30489C9.64585 8.03066 9.50476 7.78129 9.29 7.60626C8.6443 7.08021 8.7916 6.05587 9.5593 5.73314C9.81469 5.62572 10.0203 5.4262 10.1354 5.17418C10.2506 4.92215 10.2667 4.63607 10.1806 4.37271C9.92187 3.58111 10.5996 2.799 11.4199 2.94255C11.6928 2.9903 11.9737 2.93364 12.2068 2.78384C12.4399 2.63404 12.6081 2.40205 12.678 2.13396C12.8882 1.32818 13.8812 1.03661 14.4938 1.60075C14.6975 1.7885 14.9644 1.89273 15.2414 1.89273C15.5184 1.89273 15.7853 1.7885 15.989 1.60075Z"
      fill="#FFC91D"
    />
    <path
      d="M15.2412 12.3187C17.8399 12.3187 19.9466 10.212 19.9466 7.61327C19.9466 5.01453 17.8399 2.90784 15.2412 2.90784C12.6425 2.90784 10.5358 5.01453 10.5358 7.61327C10.5358 10.212 12.6425 12.3187 15.2412 12.3187Z"
      fill="#FFA91A"
    />
    <path
      d="M15.2412 11.6895C17.4924 11.6895 19.3174 9.86449 19.3174 7.61324C19.3174 5.36199 17.4924 3.53699 15.2412 3.53699C12.9899 3.53699 11.1649 5.36199 11.1649 7.61324C11.1649 9.86449 12.9899 11.6895 15.2412 11.6895Z"
      fill="#F79219"
    />
    <path
      d="M15.7956 3.57544C16.5238 4.31173 16.9738 5.32388 16.9738 6.44126C16.9738 8.69255 15.1488 10.5175 12.8976 10.5175C12.7095 10.5175 12.5246 10.5037 12.3433 10.479C13.0821 11.2261 14.1075 11.6894 15.2413 11.6894C17.4926 11.6894 19.3176 9.86442 19.3176 7.61313C19.3174 5.55005 17.7844 3.84603 15.7956 3.57544Z"
      fill="#EF7816"
    />
    <path
      d="M14.562 10.1795C14.3984 10.1794 14.2407 10.1184 14.1197 10.0082L12.1954 8.25446C12.1317 8.19637 12.08 8.12629 12.0434 8.04822C12.0067 7.97016 11.9858 7.88564 11.9818 7.79948C11.9778 7.71333 11.9908 7.62724 12.0201 7.54612C12.0494 7.46499 12.0943 7.39043 12.1524 7.32669C12.2105 7.26295 12.2806 7.21127 12.3587 7.17461C12.4367 7.13795 12.5213 7.11703 12.6074 7.11304C12.6936 7.10904 12.7797 7.12206 12.8608 7.15134C12.9419 7.18062 13.0165 7.22559 13.0802 7.28368L14.4733 8.55329L16.7839 5.50794C16.8892 5.36918 17.0453 5.27793 17.2179 5.25426C17.3904 5.23059 17.5653 5.27645 17.7041 5.38173C17.8428 5.48701 17.9341 5.64311 17.9577 5.81567C17.9814 5.98823 17.9356 6.16313 17.8303 6.30189L15.0854 9.91982C15.0241 10.0006 14.945 10.0661 14.8542 10.1111C14.7634 10.1562 14.6634 10.1796 14.562 10.1795Z"
      fill="white"
    />
  </svg>
);
const courseNames = {
  "Data-Science": "Data-Science",
  "DIGITAL-MARKETING": "DIGITAL-MARKETING",
  "Advance-Digital-Marketing-with-AI-Tools":
    "Advance-Digital-Marketing-with-AI-Tools",
  "DATA-STRUCTURES-and-ALGORITHMS": "DATA-STRUCTURES-and-ALGORITHMS",
  "Machine-Learning": "Machine-Learning",
  "Artificial-Intelligence": "Artificial-Intelligence",
  "CYBER-SECURITY": "CYBER-SECURITY",
  "Coding-for-Juniors": "Coding-for-Juniors",
  "Programming-in-C++": "Programming-in-C++",
  "Core-java": "Core-java",
  "DATA-ANALYST": "DATA-ANALYST",
  "Core-Python": "Core-Python",
  AutoCAD: "AutoCAD",
  "Graphic-Designing": "Graphic-Designing",
  "Graphic-Designing-&-Animation": "Graphic-Designing-&-Animation",
  "Cloud-Computing": "Cloud-Computing",
  "Banking-and-Finance": "Banking-and-Finance",
  "Tally-&-GST": "Tally-&-GST",
  "3ds-Max": "3ds-Max",
  DevOps: "DevOps",
};
function Course() {
  const { name } = useParams();
  const course = coursecontent.find((item) => item.name === name);
  if (!course) return "Course not found in content";
  const courseType = courseNames[name] || "";
  const hide = !courseNames[name];
  const assis = course.job === "100% Job Assistance";
  const pdf = course.brochure !== "";
  const content = course.images !== "";
  const outcome = course.out !=="";
  const disc = course.description !== "";
  return (
    <>
      <brochure.Provider
        value={{ download: course.brochure, name: course.name }}
      >
        <div className="p-5"></div>
        <header id="post-header" className="content-max-width ">
          <span className=" icon-post-header-dots-sm">
            <svg
              width="259"
              height="120"
              viewBox="0 0 259 120"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.2">
                <path
                  d="M4.22647 2.38855C4.22647 3.45315 3.36386 4.31617 2.29976 4.31617C1.23567 4.31617 0.373047 3.45315 0.373047 2.38855C0.373047 1.32396 1.23567 0.460938 2.29976 0.460938C3.36386 0.460938 4.22647 1.32396 4.22647 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M4.22647 25.52C4.22647 26.5845 3.36386 27.4476 2.29976 27.4476C1.23567 27.4476 0.373047 26.5845 0.373047 25.52C0.373047 24.4554 1.23567 23.5923 2.29976 23.5923C3.36386 23.5923 4.22647 24.4554 4.22647 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M4.22647 48.6513C4.22647 49.7159 3.36386 50.579 2.29976 50.579C1.23567 50.579 0.373047 49.7159 0.373047 48.6513C0.373047 47.5868 1.23567 46.7237 2.29976 46.7237C3.36386 46.7237 4.22647 47.5868 4.22647 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M4.22647 71.7827C4.22647 72.8473 3.36386 73.7104 2.29976 73.7104C1.23567 73.7104 0.373047 72.8473 0.373047 71.7827C0.373047 70.7182 1.23567 69.8551 2.29976 69.8551C3.36386 69.8551 4.22647 70.7182 4.22647 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M4.22647 94.9141C4.22647 95.9787 3.36386 96.8418 2.29976 96.8418C1.23567 96.8418 0.373047 95.9787 0.373047 94.9141C0.373047 93.8496 1.23567 92.9865 2.29976 92.9865C3.36386 92.9865 4.22647 93.8496 4.22647 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M4.22647 118.046C4.22647 119.11 3.36386 119.973 2.29976 119.973C1.23567 119.973 0.373047 119.11 0.373047 118.046C0.373047 116.981 1.23567 116.118 2.29976 116.118C3.36386 116.118 4.22647 116.981 4.22647 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.347 2.38855C27.347 3.45315 26.4844 4.31617 25.4203 4.31617C24.3562 4.31617 23.4936 3.45315 23.4936 2.38855C23.4936 1.32396 24.3562 0.460938 25.4203 0.460938C26.4844 0.460938 27.347 1.32396 27.347 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.347 25.52C27.347 26.5845 26.4844 27.4476 25.4203 27.4476C24.3562 27.4476 23.4936 26.5845 23.4936 25.52C23.4936 24.4554 24.3562 23.5923 25.4203 23.5923C26.4844 23.5923 27.347 24.4554 27.347 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.347 48.6513C27.347 49.7159 26.4844 50.579 25.4203 50.579C24.3562 50.579 23.4936 49.7159 23.4936 48.6513C23.4936 47.5868 24.3562 46.7237 25.4203 46.7237C26.4844 46.7237 27.347 47.5868 27.347 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.347 71.7827C27.347 72.8473 26.4844 73.7104 25.4203 73.7104C24.3562 73.7104 23.4936 72.8473 23.4936 71.7827C23.4936 70.7182 24.3562 69.8551 25.4203 69.8551C26.4844 69.8551 27.347 70.7182 27.347 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.347 94.9141C27.347 95.9787 26.4844 96.8418 25.4203 96.8418C24.3562 96.8418 23.4936 95.9787 23.4936 94.9141C23.4936 93.8496 24.3562 92.9865 25.4203 92.9865C26.4844 92.9865 27.347 93.8496 27.347 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.347 118.046C27.347 119.11 26.4844 119.973 25.4203 119.973C24.3562 119.973 23.4936 119.11 23.4936 118.046C23.4936 116.981 24.3562 116.118 25.4203 116.118C26.4844 116.118 27.347 116.981 27.347 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.4676 2.38855C50.4676 3.45315 49.605 4.31617 48.5409 4.31617C47.4768 4.31617 46.6142 3.45315 46.6142 2.38855C46.6142 1.32396 47.4768 0.460938 48.5409 0.460938C49.605 0.460938 50.4676 1.32396 50.4676 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.4676 25.52C50.4676 26.5845 49.605 27.4476 48.5409 27.4476C47.4768 27.4476 46.6142 26.5845 46.6142 25.52C46.6142 24.4554 47.4768 23.5923 48.5409 23.5923C49.605 23.5923 50.4676 24.4554 50.4676 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.4676 48.6513C50.4676 49.7159 49.605 50.579 48.5409 50.579C47.4768 50.579 46.6142 49.7159 46.6142 48.6513C46.6142 47.5868 47.4768 46.7237 48.5409 46.7237C49.605 46.7237 50.4676 47.5868 50.4676 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.4676 71.7827C50.4676 72.8473 49.605 73.7104 48.5409 73.7104C47.4768 73.7104 46.6142 72.8473 46.6142 71.7827C46.6142 70.7182 47.4768 69.8551 48.5409 69.8551C49.605 69.8551 50.4676 70.7182 50.4676 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.4676 94.9141C50.4676 95.9787 49.605 96.8418 48.5409 96.8418C47.4768 96.8418 46.6142 95.9787 46.6142 94.9141C46.6142 93.8496 47.4768 92.9865 48.5409 92.9865C49.605 92.9865 50.4676 93.8496 50.4676 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.4676 118.046C50.4676 119.11 49.605 119.973 48.5409 119.973C47.4768 119.973 46.6142 119.11 46.6142 118.046C46.6142 116.981 47.4768 116.118 48.5409 116.118C49.605 116.118 50.4676 116.981 50.4676 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.5882 2.38855C73.5882 3.45315 72.7255 4.31617 71.6615 4.31617C70.5974 4.31617 69.7347 3.45315 69.7347 2.38855C69.7347 1.32396 70.5974 0.460938 71.6615 0.460938C72.7255 0.460938 73.5882 1.32396 73.5882 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.5882 25.52C73.5882 26.5845 72.7255 27.4476 71.6615 27.4476C70.5974 27.4476 69.7347 26.5845 69.7347 25.52C69.7347 24.4554 70.5974 23.5923 71.6615 23.5923C72.7255 23.5923 73.5882 24.4554 73.5882 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.5882 48.6513C73.5882 49.7159 72.7255 50.579 71.6615 50.579C70.5974 50.579 69.7347 49.7159 69.7347 48.6513C69.7347 47.5868 70.5974 46.7237 71.6615 46.7237C72.7255 46.7237 73.5882 47.5868 73.5882 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.5882 71.7827C73.5882 72.8473 72.7255 73.7104 71.6615 73.7104C70.5974 73.7104 69.7347 72.8473 69.7347 71.7827C69.7347 70.7182 70.5974 69.8551 71.6615 69.8551C72.7255 69.8551 73.5882 70.7182 73.5882 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.5882 94.9141C73.5882 95.9787 72.7255 96.8418 71.6615 96.8418C70.5974 96.8418 69.7347 95.9787 69.7347 94.9141C69.7347 93.8496 70.5974 92.9865 71.6615 92.9865C72.7255 92.9865 73.5882 93.8496 73.5882 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.5882 118.046C73.5882 119.11 72.7255 119.973 71.6615 119.973C70.5974 119.973 69.7347 119.11 69.7347 118.046C69.7347 116.981 70.5974 116.118 71.6615 116.118C72.7255 116.118 73.5882 116.981 73.5882 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.7087 2.38855C96.7087 3.45315 95.8461 4.31617 94.782 4.31617C93.7179 4.31617 92.8553 3.45315 92.8553 2.38855C92.8553 1.32396 93.7179 0.460938 94.782 0.460938C95.8461 0.460938 96.7087 1.32396 96.7087 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.7087 25.52C96.7087 26.5845 95.8461 27.4476 94.782 27.4476C93.7179 27.4476 92.8553 26.5845 92.8553 25.52C92.8553 24.4554 93.7179 23.5923 94.782 23.5923C95.8461 23.5923 96.7087 24.4554 96.7087 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.7087 48.6513C96.7087 49.7159 95.8461 50.579 94.782 50.579C93.7179 50.579 92.8553 49.7159 92.8553 48.6513C92.8553 47.5868 93.7179 46.7237 94.782 46.7237C95.8461 46.7237 96.7087 47.5868 96.7087 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.7087 71.7827C96.7087 72.8473 95.8461 73.7104 94.782 73.7104C93.7179 73.7104 92.8553 72.8473 92.8553 71.7827C92.8553 70.7182 93.7179 69.8551 94.782 69.8551C95.8461 69.8551 96.7087 70.7182 96.7087 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.7087 94.9141C96.7087 95.9787 95.8461 96.8418 94.782 96.8418C93.7179 96.8418 92.8553 95.9787 92.8553 94.9141C92.8553 93.8496 93.7179 92.9865 94.782 92.9865C95.8461 92.9865 96.7087 93.8496 96.7087 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.7087 118.046C96.7087 119.11 95.8461 119.973 94.782 119.973C93.7179 119.973 92.8553 119.11 92.8553 118.046C92.8553 116.981 93.7179 116.118 94.782 116.118C95.8461 116.118 96.7087 116.981 96.7087 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.829 2.38855C119.829 3.45315 118.967 4.31617 117.903 4.31617C116.838 4.31617 115.976 3.45315 115.976 2.38855C115.976 1.32396 116.838 0.460938 117.903 0.460938C118.967 0.460938 119.829 1.32396 119.829 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.829 25.52C119.829 26.5845 118.967 27.4476 117.903 27.4476C116.838 27.4476 115.976 26.5845 115.976 25.52C115.976 24.4554 116.838 23.5923 117.903 23.5923C118.967 23.5923 119.829 24.4554 119.829 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.829 48.6513C119.829 49.7159 118.967 50.579 117.903 50.579C116.838 50.579 115.976 49.7159 115.976 48.6513C115.976 47.5868 116.838 46.7237 117.903 46.7237C118.967 46.7237 119.829 47.5868 119.829 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.829 71.7827C119.829 72.8473 118.967 73.7104 117.903 73.7104C116.838 73.7104 115.976 72.8473 115.976 71.7827C115.976 70.7182 116.838 69.8551 117.903 69.8551C118.967 69.8551 119.829 70.7182 119.829 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.829 94.9141C119.829 95.9787 118.967 96.8418 117.903 96.8418C116.838 96.8418 115.976 95.9787 115.976 94.9141C115.976 93.8496 116.838 92.9865 117.903 92.9865C118.967 92.9865 119.829 93.8496 119.829 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.829 118.046C119.829 119.11 118.967 119.973 117.903 119.973C116.838 119.973 115.976 119.11 115.976 118.046C115.976 116.981 116.838 116.118 117.903 116.118C118.967 116.118 119.829 116.981 119.829 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.95 2.38855C142.95 3.45315 142.087 4.31617 141.023 4.31617C139.959 4.31617 139.096 3.45315 139.096 2.38855C139.096 1.32396 139.959 0.460938 141.023 0.460938C142.087 0.460938 142.95 1.32396 142.95 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.95 25.52C142.95 26.5845 142.087 27.4476 141.023 27.4476C139.959 27.4476 139.096 26.5845 139.096 25.52C139.096 24.4554 139.959 23.5923 141.023 23.5923C142.087 23.5923 142.95 24.4554 142.95 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.95 48.6513C142.95 49.7159 142.087 50.579 141.023 50.579C139.959 50.579 139.096 49.7159 139.096 48.6513C139.096 47.5868 139.959 46.7237 141.023 46.7237C142.087 46.7237 142.95 47.5868 142.95 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.95 71.7827C142.95 72.8473 142.087 73.7104 141.023 73.7104C139.959 73.7104 139.096 72.8473 139.096 71.7827C139.096 70.7182 139.959 69.8551 141.023 69.8551C142.087 69.8551 142.95 70.7182 142.95 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.95 94.9141C142.95 95.9787 142.087 96.8418 141.023 96.8418C139.959 96.8418 139.096 95.9787 139.096 94.9141C139.096 93.8496 139.959 92.9865 141.023 92.9865C142.087 92.9865 142.95 93.8496 142.95 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.95 118.046C142.95 119.11 142.087 119.973 141.023 119.973C139.959 119.973 139.096 119.11 139.096 118.046C139.096 116.981 139.959 116.118 141.023 116.118C142.087 116.118 142.95 116.981 142.95 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M166.07 2.38855C166.07 3.45315 165.208 4.31617 164.144 4.31617C163.08 4.31617 162.217 3.45315 162.217 2.38855C162.217 1.32396 163.08 0.460938 164.144 0.460938C165.208 0.460938 166.07 1.32396 166.07 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M166.07 25.52C166.07 26.5845 165.208 27.4476 164.144 27.4476C163.08 27.4476 162.217 26.5845 162.217 25.52C162.217 24.4554 163.08 23.5923 164.144 23.5923C165.208 23.5923 166.07 24.4554 166.07 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M166.07 48.6513C166.07 49.7159 165.208 50.579 164.144 50.579C163.08 50.579 162.217 49.7159 162.217 48.6513C162.217 47.5868 163.08 46.7237 164.144 46.7237C165.208 46.7237 166.07 47.5868 166.07 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M166.07 71.7827C166.07 72.8473 165.208 73.7104 164.144 73.7104C163.08 73.7104 162.217 72.8473 162.217 71.7827C162.217 70.7182 163.08 69.8551 164.144 69.8551C165.208 69.8551 166.07 70.7182 166.07 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M166.07 94.9141C166.07 95.9787 165.208 96.8418 164.144 96.8418C163.08 96.8418 162.217 95.9787 162.217 94.9141C162.217 93.8496 163.08 92.9865 164.144 92.9865C165.208 92.9865 166.07 93.8496 166.07 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M166.07 118.046C166.07 119.11 165.208 119.973 164.144 119.973C163.08 119.973 162.217 119.11 162.217 118.046C162.217 116.981 163.08 116.118 164.144 116.118C165.208 116.118 166.07 116.981 166.07 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M189.191 2.38855C189.191 3.45315 188.328 4.31617 187.264 4.31617C186.2 4.31617 185.338 3.45315 185.338 2.38855C185.338 1.32396 186.2 0.460938 187.264 0.460938C188.328 0.460938 189.191 1.32396 189.191 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M189.191 25.52C189.191 26.5845 188.328 27.4476 187.264 27.4476C186.2 27.4476 185.338 26.5845 185.338 25.52C185.338 24.4554 186.2 23.5923 187.264 23.5923C188.328 23.5923 189.191 24.4554 189.191 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M189.191 48.6513C189.191 49.7159 188.328 50.579 187.264 50.579C186.2 50.579 185.338 49.7159 185.338 48.6513C185.338 47.5868 186.2 46.7237 187.264 46.7237C188.328 46.7237 189.191 47.5868 189.191 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M189.191 71.7827C189.191 72.8473 188.328 73.7104 187.264 73.7104C186.2 73.7104 185.338 72.8473 185.338 71.7827C185.338 70.7182 186.2 69.8551 187.264 69.8551C188.328 69.8551 189.191 70.7182 189.191 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M189.191 94.9141C189.191 95.9787 188.328 96.8418 187.264 96.8418C186.2 96.8418 185.338 95.9787 185.338 94.9141C185.338 93.8496 186.2 92.9865 187.264 92.9865C188.328 92.9865 189.191 93.8496 189.191 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M189.191 118.046C189.191 119.11 188.328 119.973 187.264 119.973C186.2 119.973 185.338 119.11 185.338 118.046C185.338 116.981 186.2 116.118 187.264 116.118C188.328 116.118 189.191 116.981 189.191 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.312 2.38855C212.312 3.45315 211.449 4.31617 210.385 4.31617C209.321 4.31617 208.458 3.45315 208.458 2.38855C208.458 1.32396 209.321 0.460938 210.385 0.460938C211.449 0.460938 212.312 1.32396 212.312 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.312 25.52C212.312 26.5845 211.449 27.4476 210.385 27.4476C209.321 27.4476 208.458 26.5845 208.458 25.52C208.458 24.4554 209.321 23.5923 210.385 23.5923C211.449 23.5923 212.312 24.4554 212.312 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.312 48.6513C212.312 49.7159 211.449 50.579 210.385 50.579C209.321 50.579 208.458 49.7159 208.458 48.6513C208.458 47.5868 209.321 46.7237 210.385 46.7237C211.449 46.7237 212.312 47.5868 212.312 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.312 71.7827C212.312 72.8473 211.449 73.7104 210.385 73.7104C209.321 73.7104 208.458 72.8473 208.458 71.7827C208.458 70.7182 209.321 69.8551 210.385 69.8551C211.449 69.8551 212.312 70.7182 212.312 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.312 94.9141C212.312 95.9787 211.449 96.8418 210.385 96.8418C209.321 96.8418 208.458 95.9787 208.458 94.9141C208.458 93.8496 209.321 92.9865 210.385 92.9865C211.449 92.9865 212.312 93.8496 212.312 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.312 118.046C212.312 119.11 211.449 119.973 210.385 119.973C209.321 119.973 208.458 119.11 208.458 118.046C208.458 116.981 209.321 116.118 210.385 116.118C211.449 116.118 212.312 116.981 212.312 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.432 2.38855C235.432 3.45315 234.57 4.31617 233.505 4.31617C232.441 4.31617 231.579 3.45315 231.579 2.38855C231.579 1.32396 232.441 0.460938 233.505 0.460938C234.57 0.460938 235.432 1.32396 235.432 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.432 25.52C235.432 26.5845 234.57 27.4476 233.505 27.4476C232.441 27.4476 231.579 26.5845 231.579 25.52C231.579 24.4554 232.441 23.5923 233.505 23.5923C234.57 23.5923 235.432 24.4554 235.432 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.432 48.6513C235.432 49.7159 234.57 50.579 233.505 50.579C232.441 50.579 231.579 49.7159 231.579 48.6513C231.579 47.5868 232.441 46.7237 233.505 46.7237C234.57 46.7237 235.432 47.5868 235.432 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.432 71.7827C235.432 72.8473 234.57 73.7104 233.505 73.7104C232.441 73.7104 231.579 72.8473 231.579 71.7827C231.579 70.7182 232.441 69.8551 233.505 69.8551C234.57 69.8551 235.432 70.7182 235.432 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.432 94.9141C235.432 95.9787 234.57 96.8418 233.505 96.8418C232.441 96.8418 231.579 95.9787 231.579 94.9141C231.579 93.8496 232.441 92.9865 233.505 92.9865C234.57 92.9865 235.432 93.8496 235.432 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.432 118.046C235.432 119.11 234.57 119.973 233.505 119.973C232.441 119.973 231.579 119.11 231.579 118.046C231.579 116.981 232.441 116.118 233.505 116.118C234.57 116.118 235.432 116.981 235.432 118.046Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.553 2.38855C258.553 3.45315 257.69 4.31617 256.626 4.31617C255.562 4.31617 254.699 3.45315 254.699 2.38855C254.699 1.32396 255.562 0.460938 256.626 0.460938C257.69 0.460938 258.553 1.32396 258.553 2.38855Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.553 25.52C258.553 26.5845 257.69 27.4476 256.626 27.4476C255.562 27.4476 254.699 26.5845 254.699 25.52C254.699 24.4554 255.562 23.5923 256.626 23.5923C257.69 23.5923 258.553 24.4554 258.553 25.52Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.553 48.6513C258.553 49.7159 257.69 50.579 256.626 50.579C255.562 50.579 254.699 49.7159 254.699 48.6513C254.699 47.5868 255.562 46.7237 256.626 46.7237C257.69 46.7237 258.553 47.5868 258.553 48.6513Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.553 71.7827C258.553 72.8473 257.69 73.7104 256.626 73.7104C255.562 73.7104 254.699 72.8473 254.699 71.7827C254.699 70.7182 255.562 69.8551 256.626 69.8551C257.69 69.8551 258.553 70.7182 258.553 71.7827Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.553 94.9141C258.553 95.9787 257.69 96.8418 256.626 96.8418C255.562 96.8418 254.699 95.9787 254.699 94.9141C254.699 93.8496 255.562 92.9865 256.626 92.9865C257.69 92.9865 258.553 93.8496 258.553 94.9141Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.553 118.046C258.553 119.11 257.69 119.973 256.626 119.973C255.562 119.973 254.699 119.11 254.699 118.046C254.699 116.981 255.562 116.118 256.626 116.118C257.69 116.118 258.553 116.981 258.553 118.046Z"
                  fill="#9674CC"
                ></path>
              </g>
            </svg>
          </span>{" "}
          <span className=" icon-post-header-dots-lg">
            <svg
              width="467"
              height="189"
              viewBox="0 0 467 189"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path
                  d="M3.92374 1.92762C3.92374 2.99221 3.06112 3.85523 1.99703 3.85523C0.932932 3.85523 0.0703125 2.99221 0.0703125 1.92762C0.0703125 0.863023 0.932932 0 1.99703 0C3.06112 0 3.92374 0.863023 3.92374 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 25.059C3.92374 26.1236 3.06112 26.9866 1.99703 26.9866C0.932932 26.9866 0.0703125 26.1236 0.0703125 25.059C0.0703125 23.9944 0.932932 23.1314 1.99703 23.1314C3.06112 23.1314 3.92374 23.9944 3.92374 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 48.1904C3.92374 49.255 3.06112 50.118 1.99703 50.118C0.932932 50.118 0.0703125 49.255 0.0703125 48.1904C0.0703125 47.1258 0.932932 46.2628 1.99703 46.2628C3.06112 46.2628 3.92374 47.1258 3.92374 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 71.3218C3.92374 72.3864 3.06112 73.2494 1.99703 73.2494C0.932932 73.2494 0.0703125 72.3864 0.0703125 71.3218C0.0703125 70.2572 0.932932 69.3942 1.99703 69.3942C3.06112 69.3942 3.92374 70.2572 3.92374 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 94.4532C3.92374 95.5178 3.06112 96.3808 1.99703 96.3808C0.932932 96.3808 0.0703125 95.5178 0.0703125 94.4532C0.0703125 93.3886 0.932932 92.5256 1.99703 92.5256C3.06112 92.5256 3.92374 93.3886 3.92374 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 117.585C3.92374 118.649 3.06112 119.512 1.99703 119.512C0.932932 119.512 0.0703125 118.649 0.0703125 117.585C0.0703125 116.52 0.932932 115.657 1.99703 115.657C3.06112 115.657 3.92374 116.52 3.92374 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 140.716C3.92374 141.781 3.06112 142.644 1.99703 142.644C0.932932 142.644 0.0703125 141.781 0.0703125 140.716C0.0703125 139.651 0.932932 138.788 1.99703 138.788C3.06112 138.788 3.92374 139.651 3.92374 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 163.847C3.92374 164.912 3.06112 165.775 1.99703 165.775C0.932932 165.775 0.0703125 164.912 0.0703125 163.847C0.0703125 162.783 0.932932 161.92 1.99703 161.92C3.06112 161.92 3.92374 162.783 3.92374 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M3.92374 186.979C3.92374 188.043 3.06112 188.906 1.99703 188.906C0.932932 188.906 0.0703125 188.043 0.0703125 186.979C0.0703125 185.914 0.932932 185.051 1.99703 185.051C3.06112 185.051 3.92374 185.914 3.92374 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 1.92762C27.0443 2.99221 26.1817 3.85523 25.1176 3.85523C24.0535 3.85523 23.1909 2.99221 23.1909 1.92762C23.1909 0.863023 24.0535 0 25.1176 0C26.1817 0 27.0443 0.863023 27.0443 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 25.059C27.0443 26.1236 26.1817 26.9866 25.1176 26.9866C24.0535 26.9866 23.1909 26.1236 23.1909 25.059C23.1909 23.9944 24.0535 23.1314 25.1176 23.1314C26.1817 23.1314 27.0443 23.9944 27.0443 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 48.1904C27.0443 49.255 26.1817 50.118 25.1176 50.118C24.0535 50.118 23.1909 49.255 23.1909 48.1904C23.1909 47.1258 24.0535 46.2628 25.1176 46.2628C26.1817 46.2628 27.0443 47.1258 27.0443 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 71.3218C27.0443 72.3864 26.1817 73.2494 25.1176 73.2494C24.0535 73.2494 23.1909 72.3864 23.1909 71.3218C23.1909 70.2572 24.0535 69.3942 25.1176 69.3942C26.1817 69.3942 27.0443 70.2572 27.0443 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 94.4532C27.0443 95.5178 26.1817 96.3808 25.1176 96.3808C24.0535 96.3808 23.1909 95.5178 23.1909 94.4532C23.1909 93.3886 24.0535 92.5256 25.1176 92.5256C26.1817 92.5256 27.0443 93.3886 27.0443 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 117.585C27.0443 118.649 26.1817 119.512 25.1176 119.512C24.0535 119.512 23.1909 118.649 23.1909 117.585C23.1909 116.52 24.0535 115.657 25.1176 115.657C26.1817 115.657 27.0443 116.52 27.0443 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 140.716C27.0443 141.781 26.1817 142.644 25.1176 142.644C24.0535 142.644 23.1909 141.781 23.1909 140.716C23.1909 139.651 24.0535 138.788 25.1176 138.788C26.1817 138.788 27.0443 139.651 27.0443 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 163.847C27.0443 164.912 26.1817 165.775 25.1176 165.775C24.0535 165.775 23.1909 164.912 23.1909 163.847C23.1909 162.783 24.0535 161.92 25.1176 161.92C26.1817 161.92 27.0443 162.783 27.0443 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M27.0443 186.979C27.0443 188.043 26.1817 188.906 25.1176 188.906C24.0535 188.906 23.1909 188.043 23.1909 186.979C23.1909 185.914 24.0535 185.051 25.1176 185.051C26.1817 185.051 27.0443 185.914 27.0443 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 1.92762C50.1649 2.99221 49.3022 3.85523 48.2382 3.85523C47.1741 3.85523 46.3114 2.99221 46.3114 1.92762C46.3114 0.863023 47.1741 0 48.2382 0C49.3022 0 50.1649 0.863023 50.1649 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 25.059C50.1649 26.1236 49.3022 26.9866 48.2382 26.9866C47.1741 26.9866 46.3114 26.1236 46.3114 25.059C46.3114 23.9944 47.1741 23.1314 48.2382 23.1314C49.3022 23.1314 50.1649 23.9944 50.1649 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 48.1904C50.1649 49.255 49.3022 50.118 48.2382 50.118C47.1741 50.118 46.3114 49.255 46.3114 48.1904C46.3114 47.1258 47.1741 46.2628 48.2382 46.2628C49.3022 46.2628 50.1649 47.1258 50.1649 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 71.3218C50.1649 72.3864 49.3022 73.2494 48.2382 73.2494C47.1741 73.2494 46.3114 72.3864 46.3114 71.3218C46.3114 70.2572 47.1741 69.3942 48.2382 69.3942C49.3022 69.3942 50.1649 70.2572 50.1649 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 94.4532C50.1649 95.5178 49.3022 96.3808 48.2382 96.3808C47.1741 96.3808 46.3114 95.5178 46.3114 94.4532C46.3114 93.3886 47.1741 92.5256 48.2382 92.5256C49.3022 92.5256 50.1649 93.3886 50.1649 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 117.585C50.1649 118.649 49.3022 119.512 48.2382 119.512C47.1741 119.512 46.3114 118.649 46.3114 117.585C46.3114 116.52 47.1741 115.657 48.2382 115.657C49.3022 115.657 50.1649 116.52 50.1649 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 140.716C50.1649 141.781 49.3022 142.644 48.2382 142.644C47.1741 142.644 46.3114 141.781 46.3114 140.716C46.3114 139.651 47.1741 138.788 48.2382 138.788C49.3022 138.788 50.1649 139.651 50.1649 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 163.847C50.1649 164.912 49.3022 165.775 48.2382 165.775C47.1741 165.775 46.3114 164.912 46.3114 163.847C46.3114 162.783 47.1741 161.92 48.2382 161.92C49.3022 161.92 50.1649 162.783 50.1649 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M50.1649 186.979C50.1649 188.043 49.3022 188.906 48.2382 188.906C47.1741 188.906 46.3114 188.043 46.3114 186.979C46.3114 185.914 47.1741 185.051 48.2382 185.051C49.3022 185.051 50.1649 185.914 50.1649 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 1.92762C73.2854 2.99221 72.4228 3.85523 71.3587 3.85523C70.2946 3.85523 69.432 2.99221 69.432 1.92762C69.432 0.863023 70.2946 0 71.3587 0C72.4228 0 73.2854 0.863023 73.2854 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 25.059C73.2854 26.1236 72.4228 26.9866 71.3587 26.9866C70.2946 26.9866 69.432 26.1236 69.432 25.059C69.432 23.9944 70.2946 23.1314 71.3587 23.1314C72.4228 23.1314 73.2854 23.9944 73.2854 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 48.1904C73.2854 49.255 72.4228 50.118 71.3587 50.118C70.2946 50.118 69.432 49.255 69.432 48.1904C69.432 47.1258 70.2946 46.2628 71.3587 46.2628C72.4228 46.2628 73.2854 47.1258 73.2854 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 71.3218C73.2854 72.3864 72.4228 73.2494 71.3587 73.2494C70.2946 73.2494 69.432 72.3864 69.432 71.3218C69.432 70.2572 70.2946 69.3942 71.3587 69.3942C72.4228 69.3942 73.2854 70.2572 73.2854 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 94.4532C73.2854 95.5178 72.4228 96.3808 71.3587 96.3808C70.2946 96.3808 69.432 95.5178 69.432 94.4532C69.432 93.3886 70.2946 92.5256 71.3587 92.5256C72.4228 92.5256 73.2854 93.3886 73.2854 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 117.585C73.2854 118.649 72.4228 119.512 71.3587 119.512C70.2946 119.512 69.432 118.649 69.432 117.585C69.432 116.52 70.2946 115.657 71.3587 115.657C72.4228 115.657 73.2854 116.52 73.2854 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 140.716C73.2854 141.781 72.4228 142.644 71.3587 142.644C70.2946 142.644 69.432 141.781 69.432 140.716C69.432 139.651 70.2946 138.788 71.3587 138.788C72.4228 138.788 73.2854 139.651 73.2854 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 163.847C73.2854 164.912 72.4228 165.775 71.3587 165.775C70.2946 165.775 69.432 164.912 69.432 163.847C69.432 162.783 70.2946 161.92 71.3587 161.92C72.4228 161.92 73.2854 162.783 73.2854 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M73.2854 186.979C73.2854 188.043 72.4228 188.906 71.3587 188.906C70.2946 188.906 69.432 188.043 69.432 186.979C69.432 185.914 70.2946 185.051 71.3587 185.051C72.4228 185.051 73.2854 185.914 73.2854 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 1.92762C96.406 2.99221 95.5434 3.85523 94.4793 3.85523C93.4152 3.85523 92.5526 2.99221 92.5526 1.92762C92.5526 0.863023 93.4152 0 94.4793 0C95.5434 0 96.406 0.863023 96.406 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 25.059C96.406 26.1236 95.5434 26.9866 94.4793 26.9866C93.4152 26.9866 92.5526 26.1236 92.5526 25.059C92.5526 23.9944 93.4152 23.1314 94.4793 23.1314C95.5434 23.1314 96.406 23.9944 96.406 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 48.1904C96.406 49.255 95.5434 50.118 94.4793 50.118C93.4152 50.118 92.5526 49.255 92.5526 48.1904C92.5526 47.1258 93.4152 46.2628 94.4793 46.2628C95.5434 46.2628 96.406 47.1258 96.406 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 71.3218C96.406 72.3864 95.5434 73.2494 94.4793 73.2494C93.4152 73.2494 92.5526 72.3864 92.5526 71.3218C92.5526 70.2572 93.4152 69.3942 94.4793 69.3942C95.5434 69.3942 96.406 70.2572 96.406 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 94.4532C96.406 95.5178 95.5434 96.3808 94.4793 96.3808C93.4152 96.3808 92.5526 95.5178 92.5526 94.4532C92.5526 93.3886 93.4152 92.5256 94.4793 92.5256C95.5434 92.5256 96.406 93.3886 96.406 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 117.585C96.406 118.649 95.5434 119.512 94.4793 119.512C93.4152 119.512 92.5526 118.649 92.5526 117.585C92.5526 116.52 93.4152 115.657 94.4793 115.657C95.5434 115.657 96.406 116.52 96.406 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 140.716C96.406 141.781 95.5434 142.644 94.4793 142.644C93.4152 142.644 92.5526 141.781 92.5526 140.716C92.5526 139.651 93.4152 138.788 94.4793 138.788C95.5434 138.788 96.406 139.651 96.406 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 163.847C96.406 164.912 95.5434 165.775 94.4793 165.775C93.4152 165.775 92.5526 164.912 92.5526 163.847C92.5526 162.783 93.4152 161.92 94.4793 161.92C95.5434 161.92 96.406 162.783 96.406 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M96.406 186.979C96.406 188.043 95.5434 188.906 94.4793 188.906C93.4152 188.906 92.5526 188.043 92.5526 186.979C92.5526 185.914 93.4152 185.051 94.4793 185.051C95.5434 185.051 96.406 185.914 96.406 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 1.92762C119.527 2.99221 118.664 3.85523 117.6 3.85523C116.536 3.85523 115.673 2.99221 115.673 1.92762C115.673 0.863023 116.536 0 117.6 0C118.664 0 119.527 0.863023 119.527 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 25.059C119.527 26.1236 118.664 26.9866 117.6 26.9866C116.536 26.9866 115.673 26.1236 115.673 25.059C115.673 23.9944 116.536 23.1314 117.6 23.1314C118.664 23.1314 119.527 23.9944 119.527 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 48.1904C119.527 49.255 118.664 50.118 117.6 50.118C116.536 50.118 115.673 49.255 115.673 48.1904C115.673 47.1258 116.536 46.2628 117.6 46.2628C118.664 46.2628 119.527 47.1258 119.527 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 71.3218C119.527 72.3864 118.664 73.2494 117.6 73.2494C116.536 73.2494 115.673 72.3864 115.673 71.3218C115.673 70.2572 116.536 69.3942 117.6 69.3942C118.664 69.3942 119.527 70.2572 119.527 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 94.4532C119.527 95.5178 118.664 96.3808 117.6 96.3808C116.536 96.3808 115.673 95.5178 115.673 94.4532C115.673 93.3886 116.536 92.5256 117.6 92.5256C118.664 92.5256 119.527 93.3886 119.527 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 117.585C119.527 118.649 118.664 119.512 117.6 119.512C116.536 119.512 115.673 118.649 115.673 117.585C115.673 116.52 116.536 115.657 117.6 115.657C118.664 115.657 119.527 116.52 119.527 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 140.716C119.527 141.781 118.664 142.644 117.6 142.644C116.536 142.644 115.673 141.781 115.673 140.716C115.673 139.651 116.536 138.788 117.6 138.788C118.664 138.788 119.527 139.651 119.527 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 163.847C119.527 164.912 118.664 165.775 117.6 165.775C116.536 165.775 115.673 164.912 115.673 163.847C115.673 162.783 116.536 161.92 117.6 161.92C118.664 161.92 119.527 162.783 119.527 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M119.527 186.979C119.527 188.043 118.664 188.906 117.6 188.906C116.536 188.906 115.673 188.043 115.673 186.979C115.673 185.914 116.536 185.051 117.6 185.051C118.664 185.051 119.527 185.914 119.527 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 1.92762C142.647 2.99221 141.785 3.85523 140.72 3.85523C139.656 3.85523 138.794 2.99221 138.794 1.92762C138.794 0.863023 139.656 0 140.72 0C141.785 0 142.647 0.863023 142.647 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 25.059C142.647 26.1236 141.785 26.9866 140.72 26.9866C139.656 26.9866 138.794 26.1236 138.794 25.059C138.794 23.9944 139.656 23.1314 140.72 23.1314C141.785 23.1314 142.647 23.9944 142.647 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 48.1904C142.647 49.255 141.785 50.118 140.72 50.118C139.656 50.118 138.794 49.255 138.794 48.1904C138.794 47.1258 139.656 46.2628 140.72 46.2628C141.785 46.2628 142.647 47.1258 142.647 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 71.3218C142.647 72.3864 141.785 73.2494 140.72 73.2494C139.656 73.2494 138.794 72.3864 138.794 71.3218C138.794 70.2572 139.656 69.3942 140.72 69.3942C141.785 69.3942 142.647 70.2572 142.647 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 94.4532C142.647 95.5178 141.785 96.3808 140.72 96.3808C139.656 96.3808 138.794 95.5178 138.794 94.4532C138.794 93.3886 139.656 92.5256 140.72 92.5256C141.785 92.5256 142.647 93.3886 142.647 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 117.585C142.647 118.649 141.785 119.512 140.72 119.512C139.656 119.512 138.794 118.649 138.794 117.585C138.794 116.52 139.656 115.657 140.72 115.657C141.785 115.657 142.647 116.52 142.647 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 140.716C142.647 141.781 141.785 142.644 140.72 142.644C139.656 142.644 138.794 141.781 138.794 140.716C138.794 139.651 139.656 138.788 140.72 138.788C141.785 138.788 142.647 139.651 142.647 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 163.847C142.647 164.912 141.785 165.775 140.72 165.775C139.656 165.775 138.794 164.912 138.794 163.847C138.794 162.783 139.656 161.92 140.72 161.92C141.785 161.92 142.647 162.783 142.647 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M142.647 186.979C142.647 188.043 141.785 188.906 140.72 188.906C139.656 188.906 138.794 188.043 138.794 186.979C138.794 185.914 139.656 185.051 140.72 185.051C141.785 185.051 142.647 185.914 142.647 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 1.92762C165.768 2.99221 164.905 3.85523 163.841 3.85523C162.777 3.85523 161.914 2.99221 161.914 1.92762C161.914 0.863023 162.777 0 163.841 0C164.905 0 165.768 0.863023 165.768 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 25.059C165.768 26.1236 164.905 26.9866 163.841 26.9866C162.777 26.9866 161.914 26.1236 161.914 25.059C161.914 23.9944 162.777 23.1314 163.841 23.1314C164.905 23.1314 165.768 23.9944 165.768 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 48.1904C165.768 49.255 164.905 50.118 163.841 50.118C162.777 50.118 161.914 49.255 161.914 48.1904C161.914 47.1258 162.777 46.2628 163.841 46.2628C164.905 46.2628 165.768 47.1258 165.768 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 71.3218C165.768 72.3864 164.905 73.2494 163.841 73.2494C162.777 73.2494 161.914 72.3864 161.914 71.3218C161.914 70.2572 162.777 69.3942 163.841 69.3942C164.905 69.3942 165.768 70.2572 165.768 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 94.4532C165.768 95.5178 164.905 96.3808 163.841 96.3808C162.777 96.3808 161.914 95.5178 161.914 94.4532C161.914 93.3886 162.777 92.5256 163.841 92.5256C164.905 92.5256 165.768 93.3886 165.768 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 117.585C165.768 118.649 164.905 119.512 163.841 119.512C162.777 119.512 161.914 118.649 161.914 117.585C161.914 116.52 162.777 115.657 163.841 115.657C164.905 115.657 165.768 116.52 165.768 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 140.716C165.768 141.781 164.905 142.644 163.841 142.644C162.777 142.644 161.914 141.781 161.914 140.716C161.914 139.651 162.777 138.788 163.841 138.788C164.905 138.788 165.768 139.651 165.768 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 163.847C165.768 164.912 164.905 165.775 163.841 165.775C162.777 165.775 161.914 164.912 161.914 163.847C161.914 162.783 162.777 161.92 163.841 161.92C164.905 161.92 165.768 162.783 165.768 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M165.768 186.979C165.768 188.043 164.905 188.906 163.841 188.906C162.777 188.906 161.914 188.043 161.914 186.979C161.914 185.914 162.777 185.051 163.841 185.051C164.905 185.051 165.768 185.914 165.768 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 1.92762C188.888 2.99221 188.026 3.85523 186.962 3.85523C185.897 3.85523 185.035 2.99221 185.035 1.92762C185.035 0.863023 185.897 0 186.962 0C188.026 0 188.888 0.863023 188.888 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 25.059C188.888 26.1236 188.026 26.9866 186.962 26.9866C185.897 26.9866 185.035 26.1236 185.035 25.059C185.035 23.9944 185.897 23.1314 186.962 23.1314C188.026 23.1314 188.888 23.9944 188.888 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 48.1904C188.888 49.255 188.026 50.118 186.962 50.118C185.897 50.118 185.035 49.255 185.035 48.1904C185.035 47.1258 185.897 46.2628 186.962 46.2628C188.026 46.2628 188.888 47.1258 188.888 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 71.3218C188.888 72.3864 188.026 73.2494 186.962 73.2494C185.897 73.2494 185.035 72.3864 185.035 71.3218C185.035 70.2572 185.897 69.3942 186.962 69.3942C188.026 69.3942 188.888 70.2572 188.888 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 94.4532C188.888 95.5178 188.026 96.3808 186.962 96.3808C185.897 96.3808 185.035 95.5178 185.035 94.4532C185.035 93.3886 185.897 92.5256 186.962 92.5256C188.026 92.5256 188.888 93.3886 188.888 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 117.585C188.888 118.649 188.026 119.512 186.962 119.512C185.897 119.512 185.035 118.649 185.035 117.585C185.035 116.52 185.897 115.657 186.962 115.657C188.026 115.657 188.888 116.52 188.888 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 140.716C188.888 141.781 188.026 142.644 186.962 142.644C185.897 142.644 185.035 141.781 185.035 140.716C185.035 139.651 185.897 138.788 186.962 138.788C188.026 138.788 188.888 139.651 188.888 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 163.847C188.888 164.912 188.026 165.775 186.962 165.775C185.897 165.775 185.035 164.912 185.035 163.847C185.035 162.783 185.897 161.92 186.962 161.92C188.026 161.92 188.888 162.783 188.888 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M188.888 186.979C188.888 188.043 188.026 188.906 186.962 188.906C185.897 188.906 185.035 188.043 185.035 186.979C185.035 185.914 185.897 185.051 186.962 185.051C188.026 185.051 188.888 185.914 188.888 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 1.92762C212.009 2.99221 211.146 3.85523 210.082 3.85523C209.018 3.85523 208.155 2.99221 208.155 1.92762C208.155 0.863023 209.018 0 210.082 0C211.146 0 212.009 0.863023 212.009 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 25.059C212.009 26.1236 211.146 26.9866 210.082 26.9866C209.018 26.9866 208.155 26.1236 208.155 25.059C208.155 23.9944 209.018 23.1314 210.082 23.1314C211.146 23.1314 212.009 23.9944 212.009 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 48.1904C212.009 49.255 211.146 50.118 210.082 50.118C209.018 50.118 208.155 49.255 208.155 48.1904C208.155 47.1258 209.018 46.2628 210.082 46.2628C211.146 46.2628 212.009 47.1258 212.009 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 71.3218C212.009 72.3864 211.146 73.2494 210.082 73.2494C209.018 73.2494 208.155 72.3864 208.155 71.3218C208.155 70.2572 209.018 69.3942 210.082 69.3942C211.146 69.3942 212.009 70.2572 212.009 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 94.4532C212.009 95.5178 211.146 96.3808 210.082 96.3808C209.018 96.3808 208.155 95.5178 208.155 94.4532C208.155 93.3886 209.018 92.5256 210.082 92.5256C211.146 92.5256 212.009 93.3886 212.009 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 117.585C212.009 118.649 211.146 119.512 210.082 119.512C209.018 119.512 208.155 118.649 208.155 117.585C208.155 116.52 209.018 115.657 210.082 115.657C211.146 115.657 212.009 116.52 212.009 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 140.716C212.009 141.781 211.146 142.644 210.082 142.644C209.018 142.644 208.155 141.781 208.155 140.716C208.155 139.651 209.018 138.788 210.082 138.788C211.146 138.788 212.009 139.651 212.009 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 163.847C212.009 164.912 211.146 165.775 210.082 165.775C209.018 165.775 208.155 164.912 208.155 163.847C208.155 162.783 209.018 161.92 210.082 161.92C211.146 161.92 212.009 162.783 212.009 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M212.009 186.979C212.009 188.043 211.146 188.906 210.082 188.906C209.018 188.906 208.155 188.043 208.155 186.979C208.155 185.914 209.018 185.051 210.082 185.051C211.146 185.051 212.009 185.914 212.009 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 1.92762C235.129 2.99221 234.267 3.85523 233.203 3.85523C232.139 3.85523 231.276 2.99221 231.276 1.92762C231.276 0.863023 232.139 0 233.203 0C234.267 0 235.129 0.863023 235.129 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 25.059C235.129 26.1236 234.267 26.9866 233.203 26.9866C232.139 26.9866 231.276 26.1236 231.276 25.059C231.276 23.9944 232.139 23.1314 233.203 23.1314C234.267 23.1314 235.129 23.9944 235.129 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 48.1904C235.129 49.255 234.267 50.118 233.203 50.118C232.139 50.118 231.276 49.255 231.276 48.1904C231.276 47.1258 232.139 46.2628 233.203 46.2628C234.267 46.2628 235.129 47.1258 235.129 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 71.3218C235.129 72.3864 234.267 73.2494 233.203 73.2494C232.139 73.2494 231.276 72.3864 231.276 71.3218C231.276 70.2572 232.139 69.3942 233.203 69.3942C234.267 69.3942 235.129 70.2572 235.129 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 94.4532C235.129 95.5178 234.267 96.3808 233.203 96.3808C232.139 96.3808 231.276 95.5178 231.276 94.4532C231.276 93.3886 232.139 92.5256 233.203 92.5256C234.267 92.5256 235.129 93.3886 235.129 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 117.585C235.129 118.649 234.267 119.512 233.203 119.512C232.139 119.512 231.276 118.649 231.276 117.585C231.276 116.52 232.139 115.657 233.203 115.657C234.267 115.657 235.129 116.52 235.129 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 140.716C235.129 141.781 234.267 142.644 233.203 142.644C232.139 142.644 231.276 141.781 231.276 140.716C231.276 139.651 232.139 138.788 233.203 138.788C234.267 138.788 235.129 139.651 235.129 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 163.847C235.129 164.912 234.267 165.775 233.203 165.775C232.139 165.775 231.276 164.912 231.276 163.847C231.276 162.783 232.139 161.92 233.203 161.92C234.267 161.92 235.129 162.783 235.129 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M235.129 186.979C235.129 188.043 234.267 188.906 233.203 188.906C232.139 188.906 231.276 188.043 231.276 186.979C231.276 185.914 232.139 185.051 233.203 185.051C234.267 185.051 235.129 185.914 235.129 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 1.92762C258.25 2.99221 257.387 3.85523 256.323 3.85523C255.259 3.85523 254.397 2.99221 254.397 1.92762C254.397 0.863023 255.259 0 256.323 0C257.387 0 258.25 0.863023 258.25 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 25.059C258.25 26.1236 257.387 26.9866 256.323 26.9866C255.259 26.9866 254.397 26.1236 254.397 25.059C254.397 23.9944 255.259 23.1314 256.323 23.1314C257.387 23.1314 258.25 23.9944 258.25 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 48.1904C258.25 49.255 257.387 50.118 256.323 50.118C255.259 50.118 254.397 49.255 254.397 48.1904C254.397 47.1258 255.259 46.2628 256.323 46.2628C257.387 46.2628 258.25 47.1258 258.25 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 71.3218C258.25 72.3864 257.387 73.2494 256.323 73.2494C255.259 73.2494 254.397 72.3864 254.397 71.3218C254.397 70.2572 255.259 69.3942 256.323 69.3942C257.387 69.3942 258.25 70.2572 258.25 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 94.4532C258.25 95.5178 257.387 96.3808 256.323 96.3808C255.259 96.3808 254.397 95.5178 254.397 94.4532C254.397 93.3886 255.259 92.5256 256.323 92.5256C257.387 92.5256 258.25 93.3886 258.25 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 117.585C258.25 118.649 257.387 119.512 256.323 119.512C255.259 119.512 254.397 118.649 254.397 117.585C254.397 116.52 255.259 115.657 256.323 115.657C257.387 115.657 258.25 116.52 258.25 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 140.716C258.25 141.781 257.387 142.644 256.323 142.644C255.259 142.644 254.397 141.781 254.397 140.716C254.397 139.651 255.259 138.788 256.323 138.788C257.387 138.788 258.25 139.651 258.25 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 163.847C258.25 164.912 257.387 165.775 256.323 165.775C255.259 165.775 254.397 164.912 254.397 163.847C254.397 162.783 255.259 161.92 256.323 161.92C257.387 161.92 258.25 162.783 258.25 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M258.25 186.979C258.25 188.043 257.387 188.906 256.323 188.906C255.259 188.906 254.397 188.043 254.397 186.979C254.397 185.914 255.259 185.051 256.323 185.051C257.387 185.051 258.25 185.914 258.25 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 1.92762C281.371 2.99221 280.508 3.85523 279.444 3.85523C278.38 3.85523 277.517 2.99221 277.517 1.92762C277.517 0.863023 278.38 0 279.444 0C280.508 0 281.371 0.863023 281.371 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 25.059C281.371 26.1236 280.508 26.9866 279.444 26.9866C278.38 26.9866 277.517 26.1236 277.517 25.059C277.517 23.9944 278.38 23.1314 279.444 23.1314C280.508 23.1314 281.371 23.9944 281.371 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 48.1904C281.371 49.255 280.508 50.118 279.444 50.118C278.38 50.118 277.517 49.255 277.517 48.1904C277.517 47.1258 278.38 46.2628 279.444 46.2628C280.508 46.2628 281.371 47.1258 281.371 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 71.3218C281.371 72.3864 280.508 73.2494 279.444 73.2494C278.38 73.2494 277.517 72.3864 277.517 71.3218C277.517 70.2572 278.38 69.3942 279.444 69.3942C280.508 69.3942 281.371 70.2572 281.371 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 94.4532C281.371 95.5178 280.508 96.3808 279.444 96.3808C278.38 96.3808 277.517 95.5178 277.517 94.4532C277.517 93.3886 278.38 92.5256 279.444 92.5256C280.508 92.5256 281.371 93.3886 281.371 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 117.585C281.371 118.649 280.508 119.512 279.444 119.512C278.38 119.512 277.517 118.649 277.517 117.585C277.517 116.52 278.38 115.657 279.444 115.657C280.508 115.657 281.371 116.52 281.371 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 140.716C281.371 141.781 280.508 142.644 279.444 142.644C278.38 142.644 277.517 141.781 277.517 140.716C277.517 139.651 278.38 138.788 279.444 138.788C280.508 138.788 281.371 139.651 281.371 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 163.847C281.371 164.912 280.508 165.775 279.444 165.775C278.38 165.775 277.517 164.912 277.517 163.847C277.517 162.783 278.38 161.92 279.444 161.92C280.508 161.92 281.371 162.783 281.371 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M281.371 186.979C281.371 188.043 280.508 188.906 279.444 188.906C278.38 188.906 277.517 188.043 277.517 186.979C277.517 185.914 278.38 185.051 279.444 185.051C280.508 185.051 281.371 185.914 281.371 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 1.92762C304.491 2.99221 303.628 3.85523 302.564 3.85523C301.5 3.85523 300.638 2.99221 300.638 1.92762C300.638 0.863023 301.5 0 302.564 0C303.628 0 304.491 0.863023 304.491 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 25.059C304.491 26.1236 303.628 26.9866 302.564 26.9866C301.5 26.9866 300.638 26.1236 300.638 25.059C300.638 23.9944 301.5 23.1314 302.564 23.1314C303.628 23.1314 304.491 23.9944 304.491 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 48.1904C304.491 49.255 303.628 50.118 302.564 50.118C301.5 50.118 300.638 49.255 300.638 48.1904C300.638 47.1258 301.5 46.2628 302.564 46.2628C303.628 46.2628 304.491 47.1258 304.491 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 71.3218C304.491 72.3864 303.628 73.2494 302.564 73.2494C301.5 73.2494 300.638 72.3864 300.638 71.3218C300.638 70.2572 301.5 69.3942 302.564 69.3942C303.628 69.3942 304.491 70.2572 304.491 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 94.4532C304.491 95.5178 303.628 96.3808 302.564 96.3808C301.5 96.3808 300.638 95.5178 300.638 94.4532C300.638 93.3886 301.5 92.5256 302.564 92.5256C303.628 92.5256 304.491 93.3886 304.491 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 117.585C304.491 118.649 303.628 119.512 302.564 119.512C301.5 119.512 300.638 118.649 300.638 117.585C300.638 116.52 301.5 115.657 302.564 115.657C303.628 115.657 304.491 116.52 304.491 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 140.716C304.491 141.781 303.628 142.644 302.564 142.644C301.5 142.644 300.638 141.781 300.638 140.716C300.638 139.651 301.5 138.788 302.564 138.788C303.628 138.788 304.491 139.651 304.491 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 163.847C304.491 164.912 303.628 165.775 302.564 165.775C301.5 165.775 300.638 164.912 300.638 163.847C300.638 162.783 301.5 161.92 302.564 161.92C303.628 161.92 304.491 162.783 304.491 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M304.491 186.979C304.491 188.043 303.628 188.906 302.564 188.906C301.5 188.906 300.638 188.043 300.638 186.979C300.638 185.914 301.5 185.051 302.564 185.051C303.628 185.051 304.491 185.914 304.491 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 1.92762C327.612 2.99221 326.749 3.85523 325.685 3.85523C324.621 3.85523 323.758 2.99221 323.758 1.92762C323.758 0.863023 324.621 0 325.685 0C326.749 0 327.612 0.863023 327.612 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 25.059C327.612 26.1236 326.749 26.9866 325.685 26.9866C324.621 26.9866 323.758 26.1236 323.758 25.059C323.758 23.9944 324.621 23.1314 325.685 23.1314C326.749 23.1314 327.612 23.9944 327.612 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 48.1904C327.612 49.255 326.749 50.118 325.685 50.118C324.621 50.118 323.758 49.255 323.758 48.1904C323.758 47.1258 324.621 46.2628 325.685 46.2628C326.749 46.2628 327.612 47.1258 327.612 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 71.3218C327.612 72.3864 326.749 73.2494 325.685 73.2494C324.621 73.2494 323.758 72.3864 323.758 71.3218C323.758 70.2572 324.621 69.3942 325.685 69.3942C326.749 69.3942 327.612 70.2572 327.612 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 94.4532C327.612 95.5178 326.749 96.3808 325.685 96.3808C324.621 96.3808 323.758 95.5178 323.758 94.4532C323.758 93.3886 324.621 92.5256 325.685 92.5256C326.749 92.5256 327.612 93.3886 327.612 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 117.585C327.612 118.649 326.749 119.512 325.685 119.512C324.621 119.512 323.758 118.649 323.758 117.585C323.758 116.52 324.621 115.657 325.685 115.657C326.749 115.657 327.612 116.52 327.612 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 140.716C327.612 141.781 326.749 142.644 325.685 142.644C324.621 142.644 323.758 141.781 323.758 140.716C323.758 139.651 324.621 138.788 325.685 138.788C326.749 138.788 327.612 139.651 327.612 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 163.847C327.612 164.912 326.749 165.775 325.685 165.775C324.621 165.775 323.758 164.912 323.758 163.847C323.758 162.783 324.621 161.92 325.685 161.92C326.749 161.92 327.612 162.783 327.612 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M327.612 186.979C327.612 188.043 326.749 188.906 325.685 188.906C324.621 188.906 323.758 188.043 323.758 186.979C323.758 185.914 324.621 185.051 325.685 185.051C326.749 185.051 327.612 185.914 327.612 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 1.92762C350.732 2.99221 349.87 3.85523 348.805 3.85523C347.741 3.85523 346.879 2.99221 346.879 1.92762C346.879 0.863023 347.741 0 348.805 0C349.87 0 350.732 0.863023 350.732 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 25.059C350.732 26.1236 349.87 26.9866 348.805 26.9866C347.741 26.9866 346.879 26.1236 346.879 25.059C346.879 23.9944 347.741 23.1314 348.805 23.1314C349.87 23.1314 350.732 23.9944 350.732 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 48.1904C350.732 49.255 349.87 50.118 348.805 50.118C347.741 50.118 346.879 49.255 346.879 48.1904C346.879 47.1258 347.741 46.2628 348.805 46.2628C349.87 46.2628 350.732 47.1258 350.732 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 71.3218C350.732 72.3864 349.87 73.2494 348.805 73.2494C347.741 73.2494 346.879 72.3864 346.879 71.3218C346.879 70.2572 347.741 69.3942 348.805 69.3942C349.87 69.3942 350.732 70.2572 350.732 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 94.4532C350.732 95.5178 349.87 96.3808 348.805 96.3808C347.741 96.3808 346.879 95.5178 346.879 94.4532C346.879 93.3886 347.741 92.5256 348.805 92.5256C349.87 92.5256 350.732 93.3886 350.732 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 117.585C350.732 118.649 349.87 119.512 348.805 119.512C347.741 119.512 346.879 118.649 346.879 117.585C346.879 116.52 347.741 115.657 348.805 115.657C349.87 115.657 350.732 116.52 350.732 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 140.716C350.732 141.781 349.87 142.644 348.805 142.644C347.741 142.644 346.879 141.781 346.879 140.716C346.879 139.651 347.741 138.788 348.805 138.788C349.87 138.788 350.732 139.651 350.732 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 163.847C350.732 164.912 349.87 165.775 348.805 165.775C347.741 165.775 346.879 164.912 346.879 163.847C346.879 162.783 347.741 161.92 348.805 161.92C349.87 161.92 350.732 162.783 350.732 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M350.732 186.979C350.732 188.043 349.87 188.906 348.805 188.906C347.741 188.906 346.879 188.043 346.879 186.979C346.879 185.914 347.741 185.051 348.805 185.051C349.87 185.051 350.732 185.914 350.732 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 1.92762C373.853 2.99221 372.99 3.85523 371.926 3.85523C370.862 3.85523 369.999 2.99221 369.999 1.92762C369.999 0.863023 370.862 0 371.926 0C372.99 0 373.853 0.863023 373.853 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 25.059C373.853 26.1236 372.99 26.9866 371.926 26.9866C370.862 26.9866 369.999 26.1236 369.999 25.059C369.999 23.9944 370.862 23.1314 371.926 23.1314C372.99 23.1314 373.853 23.9944 373.853 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 48.1904C373.853 49.255 372.99 50.118 371.926 50.118C370.862 50.118 369.999 49.255 369.999 48.1904C369.999 47.1258 370.862 46.2628 371.926 46.2628C372.99 46.2628 373.853 47.1258 373.853 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 71.3218C373.853 72.3864 372.99 73.2494 371.926 73.2494C370.862 73.2494 369.999 72.3864 369.999 71.3218C369.999 70.2572 370.862 69.3942 371.926 69.3942C372.99 69.3942 373.853 70.2572 373.853 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 94.4532C373.853 95.5178 372.99 96.3808 371.926 96.3808C370.862 96.3808 369.999 95.5178 369.999 94.4532C369.999 93.3886 370.862 92.5256 371.926 92.5256C372.99 92.5256 373.853 93.3886 373.853 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 117.585C373.853 118.649 372.99 119.512 371.926 119.512C370.862 119.512 369.999 118.649 369.999 117.585C369.999 116.52 370.862 115.657 371.926 115.657C372.99 115.657 373.853 116.52 373.853 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 140.716C373.853 141.781 372.99 142.644 371.926 142.644C370.862 142.644 369.999 141.781 369.999 140.716C369.999 139.651 370.862 138.788 371.926 138.788C372.99 138.788 373.853 139.651 373.853 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 163.847C373.853 164.912 372.99 165.775 371.926 165.775C370.862 165.775 369.999 164.912 369.999 163.847C369.999 162.783 370.862 161.92 371.926 161.92C372.99 161.92 373.853 162.783 373.853 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M373.853 186.979C373.853 188.043 372.99 188.906 371.926 188.906C370.862 188.906 369.999 188.043 369.999 186.979C369.999 185.914 370.862 185.051 371.926 185.051C372.99 185.051 373.853 185.914 373.853 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 1.92762C396.973 2.99221 396.111 3.85523 395.047 3.85523C393.983 3.85523 393.12 2.99221 393.12 1.92762C393.12 0.863023 393.983 0 395.047 0C396.111 0 396.973 0.863023 396.973 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 25.059C396.973 26.1236 396.111 26.9866 395.047 26.9866C393.983 26.9866 393.12 26.1236 393.12 25.059C393.12 23.9944 393.983 23.1314 395.047 23.1314C396.111 23.1314 396.973 23.9944 396.973 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 48.1904C396.973 49.255 396.111 50.118 395.047 50.118C393.983 50.118 393.12 49.255 393.12 48.1904C393.12 47.1258 393.983 46.2628 395.047 46.2628C396.111 46.2628 396.973 47.1258 396.973 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 71.3218C396.973 72.3864 396.111 73.2494 395.047 73.2494C393.983 73.2494 393.12 72.3864 393.12 71.3218C393.12 70.2572 393.983 69.3942 395.047 69.3942C396.111 69.3942 396.973 70.2572 396.973 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 94.4532C396.973 95.5178 396.111 96.3808 395.047 96.3808C393.983 96.3808 393.12 95.5178 393.12 94.4532C393.12 93.3886 393.983 92.5256 395.047 92.5256C396.111 92.5256 396.973 93.3886 396.973 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 117.585C396.973 118.649 396.111 119.512 395.047 119.512C393.983 119.512 393.12 118.649 393.12 117.585C393.12 116.52 393.983 115.657 395.047 115.657C396.111 115.657 396.973 116.52 396.973 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 140.716C396.973 141.781 396.111 142.644 395.047 142.644C393.983 142.644 393.12 141.781 393.12 140.716C393.12 139.651 393.983 138.788 395.047 138.788C396.111 138.788 396.973 139.651 396.973 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 163.847C396.973 164.912 396.111 165.775 395.047 165.775C393.983 165.775 393.12 164.912 393.12 163.847C393.12 162.783 393.983 161.92 395.047 161.92C396.111 161.92 396.973 162.783 396.973 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M396.973 186.979C396.973 188.043 396.111 188.906 395.047 188.906C393.983 188.906 393.12 188.043 393.12 186.979C393.12 185.914 393.983 185.051 395.047 185.051C396.111 185.051 396.973 185.914 396.973 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 1.92762C420.094 2.99221 419.231 3.85523 418.167 3.85523C417.103 3.85523 416.24 2.99221 416.24 1.92762C416.24 0.863023 417.103 0 418.167 0C419.231 0 420.094 0.863023 420.094 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 25.059C420.094 26.1236 419.231 26.9866 418.167 26.9866C417.103 26.9866 416.24 26.1236 416.24 25.059C416.24 23.9944 417.103 23.1314 418.167 23.1314C419.231 23.1314 420.094 23.9944 420.094 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 48.1904C420.094 49.255 419.231 50.118 418.167 50.118C417.103 50.118 416.24 49.255 416.24 48.1904C416.24 47.1258 417.103 46.2628 418.167 46.2628C419.231 46.2628 420.094 47.1258 420.094 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 71.3218C420.094 72.3864 419.231 73.2494 418.167 73.2494C417.103 73.2494 416.24 72.3864 416.24 71.3218C416.24 70.2572 417.103 69.3942 418.167 69.3942C419.231 69.3942 420.094 70.2572 420.094 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 94.4532C420.094 95.5178 419.231 96.3808 418.167 96.3808C417.103 96.3808 416.24 95.5178 416.24 94.4532C416.24 93.3886 417.103 92.5256 418.167 92.5256C419.231 92.5256 420.094 93.3886 420.094 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 117.585C420.094 118.649 419.231 119.512 418.167 119.512C417.103 119.512 416.24 118.649 416.24 117.585C416.24 116.52 417.103 115.657 418.167 115.657C419.231 115.657 420.094 116.52 420.094 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 140.716C420.094 141.781 419.231 142.644 418.167 142.644C417.103 142.644 416.24 141.781 416.24 140.716C416.24 139.651 417.103 138.788 418.167 138.788C419.231 138.788 420.094 139.651 420.094 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 163.847C420.094 164.912 419.231 165.775 418.167 165.775C417.103 165.775 416.24 164.912 416.24 163.847C416.24 162.783 417.103 161.92 418.167 161.92C419.231 161.92 420.094 162.783 420.094 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M420.094 186.979C420.094 188.043 419.231 188.906 418.167 188.906C417.103 188.906 416.24 188.043 416.24 186.979C416.24 185.914 417.103 185.051 418.167 185.051C419.231 185.051 420.094 185.914 420.094 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 1.92762C443.214 2.99221 442.352 3.85523 441.288 3.85523C440.224 3.85523 439.361 2.99221 439.361 1.92762C439.361 0.863023 440.224 0 441.288 0C442.352 0 443.214 0.863023 443.214 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 25.059C443.214 26.1236 442.352 26.9866 441.288 26.9866C440.224 26.9866 439.361 26.1236 439.361 25.059C439.361 23.9944 440.224 23.1314 441.288 23.1314C442.352 23.1314 443.214 23.9944 443.214 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 48.1904C443.214 49.255 442.352 50.118 441.288 50.118C440.224 50.118 439.361 49.255 439.361 48.1904C439.361 47.1258 440.224 46.2628 441.288 46.2628C442.352 46.2628 443.214 47.1258 443.214 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 71.3218C443.214 72.3864 442.352 73.2494 441.288 73.2494C440.224 73.2494 439.361 72.3864 439.361 71.3218C439.361 70.2572 440.224 69.3942 441.288 69.3942C442.352 69.3942 443.214 70.2572 443.214 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 94.4532C443.214 95.5178 442.352 96.3808 441.288 96.3808C440.224 96.3808 439.361 95.5178 439.361 94.4532C439.361 93.3886 440.224 92.5256 441.288 92.5256C442.352 92.5256 443.214 93.3886 443.214 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 117.585C443.214 118.649 442.352 119.512 441.288 119.512C440.224 119.512 439.361 118.649 439.361 117.585C439.361 116.52 440.224 115.657 441.288 115.657C442.352 115.657 443.214 116.52 443.214 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 140.716C443.214 141.781 442.352 142.644 441.288 142.644C440.224 142.644 439.361 141.781 439.361 140.716C439.361 139.651 440.224 138.788 441.288 138.788C442.352 138.788 443.214 139.651 443.214 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 163.847C443.214 164.912 442.352 165.775 441.288 165.775C440.224 165.775 439.361 164.912 439.361 163.847C439.361 162.783 440.224 161.92 441.288 161.92C442.352 161.92 443.214 162.783 443.214 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M443.214 186.979C443.214 188.043 442.352 188.906 441.288 188.906C440.224 188.906 439.361 188.043 439.361 186.979C439.361 185.914 440.224 185.051 441.288 185.051C442.352 185.051 443.214 185.914 443.214 186.979Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 1.92762C466.335 2.99221 465.472 3.85523 464.408 3.85523C463.344 3.85523 462.482 2.99221 462.482 1.92762C462.482 0.863023 463.344 0 464.408 0C465.472 0 466.335 0.863023 466.335 1.92762Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 25.059C466.335 26.1236 465.472 26.9866 464.408 26.9866C463.344 26.9866 462.482 26.1236 462.482 25.059C462.482 23.9944 463.344 23.1314 464.408 23.1314C465.472 23.1314 466.335 23.9944 466.335 25.059Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 48.1904C466.335 49.255 465.472 50.118 464.408 50.118C463.344 50.118 462.482 49.255 462.482 48.1904C462.482 47.1258 463.344 46.2628 464.408 46.2628C465.472 46.2628 466.335 47.1258 466.335 48.1904Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 71.3218C466.335 72.3864 465.472 73.2494 464.408 73.2494C463.344 73.2494 462.482 72.3864 462.482 71.3218C462.482 70.2572 463.344 69.3942 464.408 69.3942C465.472 69.3942 466.335 70.2572 466.335 71.3218Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 94.4532C466.335 95.5178 465.472 96.3808 464.408 96.3808C463.344 96.3808 462.482 95.5178 462.482 94.4532C462.482 93.3886 463.344 92.5256 464.408 92.5256C465.472 92.5256 466.335 93.3886 466.335 94.4532Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 117.585C466.335 118.649 465.472 119.512 464.408 119.512C463.344 119.512 462.482 118.649 462.482 117.585C462.482 116.52 463.344 115.657 464.408 115.657C465.472 115.657 466.335 116.52 466.335 117.585Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 140.716C466.335 141.781 465.472 142.644 464.408 142.644C463.344 142.644 462.482 141.781 462.482 140.716C462.482 139.651 463.344 138.788 464.408 138.788C465.472 138.788 466.335 139.651 466.335 140.716Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 163.847C466.335 164.912 465.472 165.775 464.408 165.775C463.344 165.775 462.482 164.912 462.482 163.847C462.482 162.783 463.344 161.92 464.408 161.92C465.472 161.92 466.335 162.783 466.335 163.847Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M466.335 186.979C466.335 188.043 465.472 188.906 464.408 188.906C463.344 188.906 462.482 188.043 462.482 186.979C462.482 185.914 463.344 185.051 464.408 185.051C465.472 185.051 466.335 185.914 466.335 186.979Z"
                  fill="#9674CC"
                ></path>
              </g>
            </svg>
          </span>{" "}
          <span className=" icon-post-header-stars">
            <svg
              width="71"
              height="59"
              viewBox="0 0 71 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <path
                  d="M47.3519 58.9057C47.3358 59.0314 47.1536 59.0314 47.1375 58.9057L44.827 40.8014C44.6803 39.652 43.775 38.7471 42.6252 38.6005L24.5135 36.2909C24.3876 36.2749 24.3876 36.0927 24.5135 36.0766L42.6252 33.7671C43.775 33.6204 44.6803 32.7155 44.827 31.5662L47.1375 13.4619C47.1536 13.3361 47.3358 13.3361 47.3519 13.4619L49.6624 31.5662C49.8091 32.7155 50.7144 33.6204 51.8642 33.7671L69.9759 36.0766C70.1018 36.0927 70.1018 36.2749 69.9759 36.2909L51.8642 38.6005C50.7144 38.7471 49.8091 39.652 49.6624 40.8014L47.3519 58.9057Z"
                  fill="#9674CC"
                ></path>
                <path
                  d="M11.0983 21.9021C11.0888 21.9596 11.0061 21.9596 10.9966 21.9021L9.60577 13.4617C9.51714 12.9239 9.09541 12.5023 8.55733 12.4137L0.11348 11.0235C0.0559311 11.014 0.0559311 10.9313 0.11348 10.9218L8.55733 9.53153C9.09541 9.44294 9.51714 9.02138 9.60577 8.48352L10.9966 0.0431404C11.0061 -0.0143852 11.0888 -0.0143852 11.0983 0.0431404L12.4891 8.48352C12.5778 9.02138 12.9995 9.44294 13.5376 9.53153L21.9814 10.9218C22.039 10.9313 22.039 11.014 21.9814 11.0235L13.5376 12.4137C12.9995 12.5023 12.5778 12.9239 12.4891 13.4617L11.0983 21.9021Z"
                  fill="#9674CC"
                ></path>
              </g>
            </svg>
          </span>{" "}
          <h1 className="text-center text:xl md:text-5xl bold">
            {course.title}
          </h1>
          <div
            id="post-author-info"
            className="text-center p-[5px] md:p-[20px] text-sm md:text-xl"
          >
            <div>
              <span id="post-author-desc">{course.out}</span>
            </div>
          </div>
          <div id="post-date">
            <div className="container flex justify-center items-center gap-8">
              <div className="rounde flex justify-center items-center">
                <span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                      fill="#55ABFF"
                    />
                    <path
                      d="M20 21.25C19.8355 21.251 19.6724 21.2194 19.5201 21.1572C19.3678 21.095 19.2293 21.0034 19.1125 20.8875L14.1125 15.8875C13.9967 15.7707 13.905 15.6322 13.8428 15.4799C13.7806 15.3276 13.7491 15.1645 13.75 15V8.75C13.75 8.41848 13.8817 8.10054 14.1161 7.86612C14.3506 7.6317 14.6685 7.5 15 7.5C15.3315 7.5 15.6495 7.6317 15.8839 7.86612C16.1183 8.10054 16.25 8.41848 16.25 8.75V14.4875L20.8875 19.1125C21.0047 19.2287 21.0977 19.367 21.1611 19.5193C21.2246 19.6716 21.2573 19.835 21.2573 20C21.2573 20.165 21.2246 20.3284 21.1611 20.4807C21.0977 20.633 21.0047 20.7713 20.8875 20.8875C20.7707 21.0034 20.6322 21.095 20.4799 21.1572C20.3276 21.2194 20.1645 21.251 20 21.25Z"
                      fill="#0078EE"
                    />
                  </svg>
                </span>
                <p className="text-white p-[5px]">
                  Duration: {course.Duration}
                </p>
              </div>
              {assis && (
                <div className="rounded flex text-white justify-center items-center">
                  <span>{svgtime}</span>{" "}
                  <p className="p-[5px]">{course.job} </p>
                </div>
              )}
            </div>
          </div>
        </header>
        {disc && (
          <p>{course.description}</p>
        )}
        <div className="flex justify-center items-center">
          <h1 className="sm:text-5xl text-2xl font-bold textgrad">
            Program curriculum
          </h1>
        </div>
        {hide && (
          <div>
            {Array.isArray(course.content) && course.content.length > 0 ? (
              course.content.map((art, index) => (
                <div
                  key={index}
                  className="flex justify-center align-center flex-col full px-5 py-0 md:pt-10 md:pl-10 md:pb-0 md:pr-20"
                >
                  <h3 className="sm:text-4xl text-2xl my-6 text-[#3e0c71] bold">
                    {art.side}
                  </h3>
                  <ul className="grid gap-10 md:grid-cols-3 md:gap-5 grid-cols-2">
                    {Array.isArray(art.topics) &&
                      art.topics.length > 0 &&
                      art.topics.map((topic, subIndex) => (
                        <li key={subIndex} className="contentcourse">
                          <p>{topic}</p>
                        </li>
                      ))}
                  </ul>
                </div>
              ))
            ) : (
              <p>No content available</p>
            )}
          </div>
        )}
        {courseType && (
          <div className="hello px-8">
            <ul className="grid gap-10 md:grid-cols-3 md:gap-5 grid-cols-2">
              {course.content.map((arr, ind) => (
                <li key={ind} className="contentcourse">
                  <p>{arr}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
         
        {outcome && (
          <div className="w-full flex justify-center mt-16 mb-16">
          <div className="bg-gray-800 relative rounded-2xl -mx-4 p-8 space-y-8 max-w-6xl text-center">
            <div className="space-y-4">
              <p className="text-l text-white regular">{course.out}</p>
            </div>
          </div>
        </div>
        )}

        {content && (
          <div className="tools-covered-container">
            <div className="content-container">
              <div className="tools-covered">
                <img
                  src="https://newus.in/courese/background.svg"
                  alt="dot-wave-left"
                  className="dot-wave-left"
                />
                <img
                  src="https://newus.in/courese/background.svg"
                  alt="dot-wave-right"
                  className="dot-wave-right"
                />
                <h1 className="heading bold">{course.title} tools</h1>
                <div className="description regular">
                  Gain hands-on experience with the tools used by professional
                  product managers to drive success and deliver value in the
                  real world.
                </div>
                <div className=" flex items-center justify-center w-full">
                  <ul className="grid grid-cols-2 gap-4 w-full md:grid-cols-3 lg:grid-cols-5 px-8 md:px-12">
                    {course.images.map((img, index) => (
                      <li key={index}>
                        <div className="w-full flex flex-col items-center justify-center bg-white md:px-2 md:py-6 rounded-3xl px-1 py-1 ">
                          <span>{img.img}</span>
                          <h4 className="text-black regular font-bold">
                            {img.type}
                          </h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        {pdf && <Showform />}
      </brochure.Provider>
    </>
  );
}

export default memo(Course);
